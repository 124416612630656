<template>
  <Card title="雨量监测">
    <div class="legend">
      <div class="legend-item">
        <div class="item-box1"></div>0-20mm
      </div>
      <div class="legend-item">
        <div class="item-box2"></div>20-30mm
      </div>
      <div class="legend-item">
        <div class="item-box3"></div>>30mm
      </div>
    </div>
    <div style="height: 90%;" id="rain-chart">
    </div>
  </Card>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    let value = 10,
      targetName = "";
    this.value = value;
    // 若超过30，为便于报警区段的显示，统一为35
    value = value > 30 ? 35 : value;
    if (value > 30) {
      targetName = "报警";
    } else if (value > 20) {
      targetName = "预警";
    } else {
      targetName = "正常";
    }

    this.draw(value, targetName);
  },
  methods: {
    draw(target, targetName) {
      this.myEchart?.dispose(); //销毁

      this.myEchart = echarts.init(document.getElementById("rain-chart"));

      let option = {
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "90%",
            min: 0,
            max: 40,
            splitNumber: 8,

            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.5, "#7DF8C1"],
                  [0.75, "#F0DE59"],
                  [1, "#FF6F4B"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "12%",
              width: 20,
              offsetCenter: [0, "-60%"],
              itemStyle: {
                color: "red",
              },
            },
            axisTick: {
              length: 2,
              lineStyle: {
                color: "#fff",
                width: 1,
              },
            },
            splitLine: {
              length: 3,
              lineStyle: {
                color: "inherit",
                width: 3,
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              distance: -40,
              rotate: "tangential",
              formatter: function (value) {
                if (value === 35) {
                  return "报警";
                } else if (value === 25) {
                  return "预警";
                } else if (value === 10) {
                  return "正常";
                }
                return "";
              },
            },
            title: {
              offsetCenter: [0, "-10%"],
              fontSize: 20,
              color: "#fff",
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, "-35%"],
              valueAnimation: true,
              formatter: (value) => {
                if (value > 30) {
                  return Math.round(this.value) + "";
                } else {
                  return Math.round(value) + "";
                }
              },
              color: "#fff",
            },
            data: [
              {
                value: target,
                name: targetName,
              },
            ],
          },
        ],
      };

      this.myEchart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.legend {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 1vh 0 0 0;

  .legend-item {
    display: flex;
    align-items: center;
    gap: 0.5vw;
  }

  .item-box1,
  .item-box2,
  .item-box3 {
    width: 1vw;
    height: 1vw;
  }

  .item-box1 {
    background-color: #7df8c1;
  }

  .item-box2 {
    background-color: #f0de59;
  }

  .item-box3 {
    background-color: #ff6f4b;
  }
}</style>
