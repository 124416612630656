<template>
  <Card title="水池容量">
    <div style="height: 100%;" id="water-amount-chart">
    </div>
  </Card>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {};
  },
  mounted() {
    this.draw(this.chartData);
  },
  methods: {
    draw() {
      this.myEchart?.dispose(); //销毁

      this.myEchart = echarts.init(
        document.getElementById("water-amount-chart")
      );

      let option = {
        color: "#05ABDF",
        xAxis: {
          type: "category",
          data: ["一号池", "二号池", "三号池", "四号池"],

          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false, //隐藏x轴线
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        series: [
          {
            data: [120, 200, 150, 80],
            // data: ['20%', '20%', '20%', 80],
            type: "bar",
            label: {
              show: true,
              formatter: function (e) {
                // 总量可能改；200
                return (e.data / 200) * 100 + "%";
              },
            },
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };

      this.myEchart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped></style>
