<template>
  <Card title="水池监测" width="100%">
    <div class="table">
      <table>
        <tr>
          <th>预警时间</th>
          <th>预警类型</th>
          <th>预警级别</th>
          <th>预警设备</th>
          <th>设计峰值</th>
          <th>预警发生时水位</th>
          <th>预警状态</th>
          <th>处理结果</th>
          <th>操作</th>
        </tr>
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.address }}</td>
          <td><a @click="removeAlert">解除警报</a> <a>确认警报</a></td>
        </tr>
      </table>
    </div>
  </Card>
</template>
<script>
export default {
  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          width: "8vw",
        },
        {
          title: "Age",
          dataIndex: "age",
          width: "8vw",
        },
        {
          title: "Address",
          dataIndex: "address",
        },
      ],
      data: [
        {
          key: 1,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 11,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 111,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 1111,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 11112,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 111122,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
        {
          key: 1111222,
          name: "主楼左侧1号井",
          age: "10.50m",
          address: "7.50m",
        },
      ],
    };
  },
  methods: {
    removeAlert() { },
  },
};
</script>
<style lang="less" scoped>
.table {
  grid-column-start: 1;
  grid-column-end: 4;
  height: 12vw;
  overflow: auto;
  margin: 1vh;

  table {
    width: 100%;
    font-size: 1.5vh;
    padding-left: 2vw;
  }

  tr {
    display: flex;
    align-items: center;
    padding: 1vh 0 1vh 2vw;
    background: transparent;

    border-bottom: 1px solid;
    border-image: linear-gradient(135deg,
        rgba(43, 116, 217, 0),
        rgba(43, 116, 217, 1),
        rgba(43, 116, 217, 0)) 1 1;

    &:nth-of-type(1) {
      border-top: none;
    }

    th {
      color: #96b0d9;
    }

    &:hover {
      background: #000;
    }
  }

  tr:first-child {
    position: sticky;
    top: 0;
    left: 0;
    // background: transparent;
    background-color: #121f31;
  }

  th,
  td {
    flex: 1;
    color: #fffaf7;
  }
}
</style>
<style lang="less" scoped></style>
