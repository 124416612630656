<template>
  <div>
    <div class="flex title-list-box">
      <RainAmount />
      <water-container />
      <water-monitor />
      <alert-record />
    </div>

    <!-- <Dialog></Dialog> -->
  </div>
</template>
<script>
import RainAmount from "./components/RainAmount.vue";
import WaterContainer from "./components/WaterContainer";
import WaterMonitor from "./components/WaterMonitor";
import AlertRecord from "./components/AlertRecord";
// import Dialog from "@/components/Dialog";
export default {
  components: {
    RainAmount,
    WaterContainer,
    WaterMonitor,
    AlertRecord,
    // Dialog,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title-list-box {
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    margin-bottom: 20px;
  }
}
img {
  display: inline-block;
  width: 300px;
  margin-bottom: 30px;
  margin-right: 30px;
}
</style>
